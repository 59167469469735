<template>
  <el-dialog title="Detalhe do SKU" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
    <div class="d-flex">
      <base-input class="m-1" label="Nome" v-model="sku.name" />
      <base-input class="m-1" label="EAN" v-model="sku.ean" />
      <base-input class="m-1" label="SKU no SAP" v-model="sku.skuSap" />
      <div class="m-1">
        <h5>Ativo</h5>
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
          v-model="sku.active" :sync="true" />
      </div>
      <div class="m-1">
        <h5>Sincronizar Estoque?</h5>
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
          v-model="sku.mustSyncStock" :sync="true" />
      </div>
    </div>
    <div class="flex-fill card-body">
      <h4>Midia</h4>
      <div class="d-flex">
        <base-input class="m-1 col-4" label="URL" v-model="url" />
        <base-input class="m-1 col-4" label="Nome" v-model="name" />
        <base-input class="m-1" label="Tipo">
          <el-select class="select-primary" placeholder="Tipo da Mídia" v-model="type">
            <el-option class="select-primary" v-for="(attr, index) in types" :key="index" :label="attr"
              :value="attr"></el-option>
          </el-select>
        </base-input>
        <base-button @click="addImage" round icon type="primary" class="mt-4 ml-3">
          <i class="tim-icons icon-simple-add"></i>
        </base-button>
      </div>
      <div class="d-flex">
        <ProductImageItem v-for="img in sku.imagens" :key="img.index" :image="img" @evtRemoveImage="removeImage" />
      </div>
    </div>
    <div class="flex-fill card-body">
      <h4>Atributos da SKU</h4>
      <div class="d-flex">
        <base-input class="m-1" label="Atributo">
          <el-select class="select-primary" placeholder="Atributo" v-model="specification.attributeId">
            <el-option class="select-primary" v-for="(attr, index) in attributes" :key="index" :label="attr.Name"
              :value="attr.Id"></el-option>
          </el-select>
        </base-input>
        <base-input class="m-1" label="Valor do Atributo" v-model="specification.value" />
        <base-button @click="addSpecification" round icon type="primary" class="mt-4 ml-3">
          <i class="tim-icons icon-simple-add"></i>
        </base-button>
      </div>
      <div>
        <el-table :data="sku.specifications" style="width: 100%">
          <el-table-column prop="index" label="#" :width="90"></el-table-column>
          <el-table-column prop="attributeName" label="Atributo"></el-table-column>
          <el-table-column prop="value" label="Valor do Atributo"></el-table-column>
          <el-table-column :min-width="135" align="left" label="Ações">
            <div slot-scope="props">
              <base-button class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row.index)">
                <i class="material-icons">delete_outline</i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Cancelar</el-button>
      <el-button type="primary" @click="addSku">Salvar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { helpers, required } from "@vuelidate/validators";
import ProductImageItem from "@/pages/Product/components/ProductImageItem";
import { mapState } from "vuex";

export default {
  props: {
    skuForm: {
      type: Object,
      default() {
        return {
          index: null,
          name: null,
          ean: null,
          skuSap: null,
          active: true,
          imagens: [],
          specifications: []
        }
      }
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProductImageItem,
  },
  watch: {
    skuForm: function () {
      this.sku.index = this.skuForm.index;
      this.sku.eCommerceId = this.skuForm.eCommerceId,
      this.sku.name = this.skuForm.name;
      this.sku.ean = this.skuForm.ean;
      this.sku.skuSap = this.skuForm.skuSap;
      this.sku.active = this.skuForm.active;
      this.sku.imagens = this.skuForm.imagens;
      this.sku.specifications = this.skuForm.specifications;
    }
  },
  data() {
    return {
      sku: {
        index: this.skuForm.index,
        eCommerceId: this.skuForm.eCommerceId,
        name: this.skuForm.name,
        ean: this.skuForm.ean,
        skuSap: this.skuForm.skuSap,
        active: this.skuForm.active,
        imagens: this.skuForm.imagens,
        specifications: this.skuForm.specifications
      },
      url: null,
      name: null,
      type: null,
      specification: {
        attributeId: null,
        attributeName: null,
        value: ""
      },
      types: ['Imagem', 'Video'],
      video: null,
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "attributes"]),
  },
  methods: {
    handleClose() {
      this.$emit('close');

      this.sku = {
        name: null,
        eCommerceId : null,
        ean: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications : []
      };

      this.url = null;
      this.name = null;
      this.type = null;
      this.specification.attributeId = null,
      this.specification.attributeName = null,
      this.specification.value = null;
    },
    addSku() {
      this.$emit("evtRefreshSku", this.sku);
      this.sku = {
        index: null,
        name: null,
        ean: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications: []
      };

      this.url = null;
      this.name = null;
      this.type = null;
      this.specification.attributeId = null,
      this.specification.attributeName = null,
      this.specification.value = null;
    },
    addImage() {
      if (!this.url || !this.name || !this.type) {
        this.$showError("Preencha todos os campos");
        return;
      }

      if (this.sku.imagens.length == 10) {
        this.$showError("Permitido incluir no máximo 10 imagens.");
        return;
      }

      this.sku.imagens = this.createListImageWithIndex(
        this.sku.imagens,
        { url: this.url, nome: this.name, type: this.type }
      );

      this.image = null;
    },
    addSpecification() {
      let attr = this.attributes.find(a => a.Id === this.specification.attributeId);

      this.specification.attributeName = attr.Name;

      if (!this.specification.attributeId) {
        this.$showError("Selecione o atributo");
        return;
      }

      if (!this.specification.value) {
        this.$showError("Informe o valor do atributo");
        return;
      }

      this.sku.specifications = this.createListSpecificationWithIndex(
        this.sku.specifications,
        this.specification
      );

      this.specification = { attributeName: null, attributeId: null, value: "" };
    },
    reIndexSpecifications() {
      this.sku.specifications.forEach((spc, index) => {
        spc.index = index + 1;
      });
    },
    reIndexImages() {
      this.sku.imagens.forEach((img, index) => {
        img.index = index + 1;
      });
    },
    removeImage(index) {
      this.sku.imagens = this.sku.imagens.filter((img) => img.index != index);
      this.reIndexImages();
    },
    removeSpecification(index) {
      this.sku.specifications = this.sku.specifications.filter((spc) => spc.index != index);
      this.reIndexSpecifications();
    },
    createListImageWithIndex(array, object) {
      let index = array.length + 1;
      object = object instanceof Object ? object : { url: object };
      array.push({ ...object, index: index });

      return array;
    },
    createListSpecificationWithIndex(array, object) {
      let index = array.length + 1;
      object = object instanceof Object ? object : { object };
      array.push({ ...object, index: index });

      return array;
    }
  },
  updated() {
    this.sku.index = this.skuForm.index;
    this.sku.eCommerceId = this.skuForm.eCommerceId;
    this.sku.name = this.skuForm.name;
    this.sku.ean = this.skuForm.ean;
    this.sku.skuSap = this.skuForm.skuSap;
    this.sku.active = this.skuForm.active;
    this.sku.imagens = this.skuForm.imagens;
    this.sku.specifications = this.skuForm.specifications;
  }
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>
