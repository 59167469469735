<template>
  <div class="row">

    <div class="ml-2 mr-2 row col-8">
      <div class="col-3 mb-2">
        <base-input>
          <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" v-model="filters.From"></el-date-picker>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" v-model="filters.To"></el-date-picker>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-select class="select-primary pagination-select" v-model="filters.TaxOperation" filterable
            placeholder="Operação Fiscal">
            <el-option class="select-primary" v-for="(item, index) in taxOperations" :key="index"
              :label="item.description" :value="item.value"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-select class="select-primary pagination-select" v-model="filters.MarketplaceId" filterable
            placeholder="Marketplace">
            <el-option class="select-primary" v-for="(item, index) in marketplaceIds" :key="index"
              :label="item.Id + ' - ' + item.Name" :value="item.Id"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Id Pedido Platz"
            v-model="filters.OrderId" @keyup.enter.native="filter">
          </el-input>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Pedido Marketplace"
            v-model="filters.ExternalNumberId" @keyup.enter.native="filter">
          </el-input>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Chave de Acesso" v-model="filters.NFKey"
            @keyup.enter.native="filter">
          </el-input>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Documento no SAP"
            v-model="filters.DocEntryNf" @keyup.enter.native="filter">
          </el-input>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="N° da Nota" v-model="filters.InvoiceNumber"
            @keyup.enter.native="filter">
          </el-input>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="Série" v-model="filters.Serie"
            @keyup.enter.native="filter">
          </el-input>
        </base-input>
      </div>
      <div class="col-3 mb-2">
        <base-input>
          <el-select class="select-primary pagination-select" v-model="filters.Status" placeholder="Status" filterable
            multiple>
            <el-option class="select-primary" v-for="(item, index) in statuses" :key="index" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </base-input>
      </div>

    </div>
    <div class="col-4-md-12 text-right">
      <el-button class="custom-btn-filter" @click="filter">Filtrar</el-button>

      <el-button :class="existItems ? 'custom-btn-filter' : 'custom-btn-filter-disabled'" @click="requeue"
        :disabled="!existItems">
        Reenfileirar
      </el-button>

      <el-button class="custom-btn-filter mr-2" @click="clearFilter">
        Limpar filtro
      </el-button>
      <el-dropdown @command="handleCommand">
        <el-button class="custom-btn-filter mr-2">
          Exportar<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="exportAll">Todos</el-dropdown-item> -->
          <el-dropdown-item command="exportErrors">Somente com erro</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown>
        <el-button class="custom-btn-filter">
          Ações<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <span @click="$router.push({ name: 'SyncStatus', query: { orderType: 2 } })">Atualizar Status</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus";
import { mapActions, mapState } from 'vuex';
import moment from "moment";
import constants from '@/util/constants';

export default {
  data() {
    return {
      filters: {
        From: null,
        To: null,
        TaxOperation: null,
        OrderId: null,
        InvoiceNumber: null,
        Status: null,
        ExternalNumberId: null,
        NFKey: null,
        DocEntryNf: null,
        Serie: null,
        MarketplaceId: null,
      },
      existItems: false,
      taxOperations: [],
      statuses: [],
      marketplaceIds: [],
    };
  },
  computed: {
    ...mapState("fulfillment", ["marketplaceIds"]),
  },
  methods: {
    ...mapActions("fulfillment", ["exportFulfillments", "getStatus"]),
    ...mapActions("orders", ["exportOrderError"]),
    ...mapActions('fulfillment', ['getMarketPlaceIds']),
    async loadSelects() {
      this.getMarketPlaceIds().then((response) => {
        this.marketplaceIds = response.data.sort((a, b) => a.Id - b.Id);
      });

      this.taxOperations = constants.TaxOperation.sort((a, b) => a.description.localeCompare(b.description));
    },
    filter() {
      this.$emit("filter");
    },
    value() {
      if (this.filters.From)
        this.filters.From = moment(this.filters.From)
          .format('YYYY-MM-DD HH:mm:ss');

      if (this.filters.To)
        this.filters.To = moment(this.filters.To)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DD HH:mm:ss');

      return this.filters;
    },
    requeue() {
      EventBus.$emit("requeueFulFillment");
    },
    async exportAll() {
      await this.exportFulfillments(this.filters).then(response => {
        this.downloadFile("Fulfillment.xlsx", response.data);
      });
    },
    async exportErrors() {
      let filter = this.filters;
      filter = Object.assign({ orderTypes: [constants.OrderType.Fulfillment] }, filter);

      await this.exportOrderError(filter)
        .then(response => {
          this.downloadFile("pedidos-com-erro.xlsx", response.data);
        });
    },
    async handleCommand(command) {
      switch (command) {
        case "exportAll":
          await this.exportAll();
          break;
        case "exportErrors":
          await this.exportErrors();
          break;
      }
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
    clearFilter() {
      this.filters = {
        From: null,
        To: null,
        TaxOperation: null,
        OrderId: null,
        InvoiceNumber: null,
        Status: null,
        ExternalNumberId: null,
        NFKey: null,
        DocEntryNf: null,
        Serie: null,
        MarketplaceId: null
      };
      this.$emit("filter");
    }
  },
  async mounted() {
    await this.loadSelects();
    await this.getStatus().then((response) => {
      response.data
        .forEach(sts =>
          this.statuses.push(
            {
              name: sts.AliasName,
              value: sts.BahnReference,
              id: sts.ID
            }
          ));
    });

    EventBus.$on("selectionChangeFulFillment", (items) => items > 0 ? this.existItems = true : this.existItems = false);
  }
};
</script>
