<template>
  <BaseList :title="title" ref="baseList" :totalRecords="totalRecords" @changePage="searchStockConfigurations">
    <StockConfigurationsFilter ref="filter" @filter="searchStockConfigurations" />
    <StockConfigurationsTable :stockConfigurations="stockConfigurationsToTable" @evtRefresh="searchStockConfigurations" />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import StockConfigurationsTable from "@/pages/StockConfiguration/components/StockConfigurationTable";
import StockConfigurationsFilter from "@/pages/StockConfiguration/components/StockConfigurationFilter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseList,
    StockConfigurationsTable,
    StockConfigurationsFilter,
  },
  data() {
    return {
      title: "Estoque Inteligente",
      totalRecords: 0
    };
  },
  computed: {
    ...mapGetters("stockConfiguration", ["stockConfigurationsToTable"])
  },
  methods: {
    ...mapActions("stockConfiguration", ["getStockConfigurations"]),
    async searchStockConfigurations() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getStockConfigurations({ ...paginationFilter, ...listFilter })
        .then((response) => this.totalRecords = response.data.Total);
    },
  },
  async mounted() {
    await this.searchStockConfigurations();
  },
};
</script>
