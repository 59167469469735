<template>
  <div class="row" style="float: left; margin-left: 4px">
    <span style="margin: 0 2pt">
      <base-input label="Nome">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome" v-model="filters.name">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Id Loja">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Id Loja" v-model="filters.storeId">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Id Vendedor">
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Id Vendedor" v-model="filters.sellerId">
        </el-input>
      </base-input>
    </span>
    <span style="margin: 0 2pt">
      <base-input label="Status">
        <el-select class="select-primary mb-3 pagination-select" v-model="filters.active">
          <el-option class="select-primary" v-for="item in filters.statusOptions" :key="item.name" :label="item.name"
            :value="item.value"></el-option>
        </el-select>
      </base-input>
    </span>
    <span style="margin: 23pt 2pt">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span style="margin: 23pt 2pt">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span style="margin: 23pt 2pt">
      <button class="custom-btn-add" @click="addNew">Novo</button>
    </span>
  </div>
</template>
<script>
import constants from '@/util/constants';
export default {
  data() {
    return {
      filters: {
        id: null,
        name: null,
        storeId: null,
        sellerId: null,
        active: null,
        statusOptions: constants.StatusOptions
      },
    };
  },
  methods: {
    addNew() {
      this.$router.push({ name: "FranchiseCreate" });
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        id: null,
        name: null,
        storeId: null,
        sellerId: null,
        active: null,
        statusOptions: constants.StatusOptions
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
};
</script>
