<template>
  <div class="flex-fill card-body">
    <div class="d-flex justify-content-end">
      <ProducAddSku @evtAddSku="addSku" />
    </div>
    <el-table :data="skus" style="width: 100%">
      <el-table-column prop="name" label="Nome" width="auto"> </el-table-column>
      <el-table-column prop="skuSap" label="SKU ERP"> </el-table-column>
      <el-table-column prop="eCommerceId" label="SKU ID no E-commerce" width="auto"></el-table-column>
      <el-table-column prop="cISSId" label="SKU ID no CISS" width="auto">
      </el-table-column>
      <el-table-column label="Ativo">
        <template slot-scope="scope">
          <span :class="scope.row.active ? 'status statusIntegrated' : 'status statusError'">
            {{ scope.row.active ? "Sim" : "Não" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Sincronizar Estoque?">
        <template slot-scope="scope">
          <span :class="scope.row.mustSyncStock ? 'status statusIntegrated' : 'status statusError'">
            {{ scope.row.mustSyncStock ? "Sim" : "Não" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Ações">
        <template slot-scope="scope">
          <el-tooltip content="Editar SKU" effect="light" :open-delay="100" placement="top">
            <base-button class="like !important btn-link" type="primary" size="sm" icon
              @click="edit(scope.$index, scope.row)">
              <i class="material-icons">mode_edit_outline</i>
            </base-button>
          </el-tooltip>
          <el-tooltip v-if="scope.row.active" content="Desativar SKU no produto" effect="light" :open-delay="100"
            placement="top">
            <base-button class="edit btn-link" type="danger" size="sm" icon
              @click="remove(scope.row.index, scope.row.id)">
              <i class="material-icons">block</i>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <EditSkuModal :dialogVisible="dialogVisible" :skuForm="skuCurrent" @evtRefreshSku="refreshSku"
      @close="dialogVisible = false" />
  </div>
</template>
<script>
import validateMixin from "@/mixins/validateMixin";
import ProducAddSku from "@/pages/Product/components/ProductAddSku";
import EditSkuModal from "./EditSkuModal.vue";
import { EventBus } from "@/eventBus";
import { mapActions, mapState, mapMutations } from "vuex";
import swal from "sweetalert2";

export default {
  mixins: [validateMixin],
  components: {
    ProducAddSku,
    EditSkuModal,
  },
  data() {
    return {
      skus: [],
      skuCurrent: {},
      dialogVisible: false,
      formChange: false,
      renderedComponent: false
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "formChanged"]),
  },
  methods: {
    ...mapActions("product", ["deleteSku"]),
    ...mapMutations('product', ['SET_FORM_CHANGED']),
    addSku(sku) {
      this.skus.push(sku);
    },
    edit(index, sku) {
      if ({ ...sku }.hasOwnProperty("id")) {
        if (!this.formChanged) {
          this.$router.push({ name: "SkuDetail", params: { id: sku.id } });
          return;
        }

        swal({
          title: "Ao executar essa ação, você irá perder todas as alterações feitas.",
          text: "Deseja salvar ?",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          cancelButtonText: "Não",
          confirmButtonText: "Sim",
        }).then((result) => {
          if (result.value) {
            EventBus.$emit("update-product");
          }

          this.$router.push({ name: "SkuDetail", params: { id: sku.id } });
        });
      } else {
        this.skuCurrent = Object.assign(sku, index);
        this.dialogVisible = true;
      }
    },
    refreshSku(sku) {
      let index = sku.index;
      delete sku.index;
      this.skus[index] = sku;
      this.dialogVisible = false;
    },
    remove(index, id) {
      this.deleteSku(id)
        .then(() => {
          this.$showSuccess("Sku desativada com sucesso");
          this.skus.forEach((attr) => {
            if (attr.index === index) attr.active = false;
          });
        })
        .catch((error) => this.$showError(error.response.data));
    },
    reIndexSkus() {
      this.skus.forEach((attr, index) => {
        attr.index = index + 1;
      });
    },
    value() {
      return { skus: [...this.skus] };
    },
    fillForm() {
      if (this.productCurrent && this.productCurrent.Skus) {
        this.skus = this.productCurrent.Skus
          .filter((a) => !a.IsKit)
          .map((a) => {
            return {
              id: a.Id,
              name: a.Name,
              eCommerceId: a.ECommerceId,
              ean: a.EAN,
              skuSap: a.SkuSAP,
              cISSId: a.CISSId,
              active: a.Active,
              imagens: a.Imagens.map((i) => {
                return { id: i.Id, Nome: i.Nome, url: i.Url, type: i.Type };
              }),
              specifications: a.Specifications.map((s) => {
                return { id: s.Id, attributeId: s.AttributeId, value: s.Value };
              }),
              mustSyncStock: a.MustSyncStock,
            };
          });
        this.reIndexSkus();
      }
    },
  },
  created() {
    EventBus.$on("ProductRegisterEdition", () => this.fillForm());
  },
  watch: {
    skus: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.formChange == false && this.renderedComponent) {
          this.formChange = true;
          this.SET_FORM_CHANGED(true);
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.renderedComponent = true;
    }, 3000);
  },
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>
