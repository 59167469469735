<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <div class="head-content">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
            <i class="material-icons">arrow_back</i> </base-button><span> Pedido Fullfillment - Detalhes
          </span>
        </h4>
      </div>
      <fulfillment-form :configurationChangeSkuProduct="changeSkuProduct" />
    </card>
  </transition>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import FulfillmentForm from "../components/FulfillmentForm.vue";
import { EventBus } from "@/eventBus";
import constants from '../../../util/constants';

export default {
  data() {
    return {
      changeSkuProduct: null
    }
  },
  components: {
    FulfillmentForm
  },
  computed: {
    ...mapState("health", ["displayRestrictedData"]),
    ...mapState("fulfillment", ["fulfillmentCurrent"])
  },
  methods: {
    ...mapActions("fulfillment", ["getFulfillmentById"]),
    ...mapActions("configurations", ["getConfigurationByKeys"]),

    async init() {
      let displayRestrictedData = this.displayRestrictedData;
      let id = this.$route.params.id;
      await this.getFulfillmentById({ id, displayRestrictedData });
      await this.getConfigurations();
    },
    async getConfigurations() {
      let query = {
        ecommerceName: this.fulfillmentCurrent.EcommerceName,
        keys: [constants.Configurations.ChangeWarehouseCode,
        constants.Configurations.ShowOrderItemTransfer,
        constants.Configurations.ChangeSkuProduct,
        constants.Configurations.UseNuuk,
        constants.Configurations.TrackingSourceName]
      };

      await this.getConfigurationByKeys(query).then(response => {
        this.changeSkuProduct = response.data.find(a => a.Key === constants.Configurations.ChangeSkuProduct);
      })
    },
  },
  async mounted() {
    EventBus.$on("toggleRestrictedDataDisplay", async () => {
      await this.init();
    });

    await this.init();
  }
}
</script>
