<template>
  <BaseList ref="baseList" :totalRecords="totalRecords" @changePage="searchProducts">
    <h4
      style="display: flex; align-items: center; margin-bottom: 0px"
      slot="header"
      class="card-title"
    >
      Cadastros de Produtos
    </h4>
    <ProductListFilter
      ref="filter"
      @evtExport="exportProduct"
      @evtExportMapping="exportProductMapping"
      @filter="searchProducts"
    />
    <ProductListTable
      ref="table"
      @reload="searchProducts"
      :products="productsToTable"
      @evtRefresh="searchProducts"
      :total="totalRecords"
    />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import ProductListTable from "@/pages/Product/components/list/ProductListTable";
import ProductListFilter from "@/pages/Product/components/list/ProductListFilter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseList,
    ProductListTable,
    ProductListFilter,
  },
  data() {
    return {
      title: "Cadastros de Produtos",
      totalRecords: 0,
    };
  },
  computed: {
    ...mapGetters("product", ["productsToTable"]),
  },
  methods: {
    ...mapActions("product", ["getProducts", "exportProducts", "exportProductsMapping"]),
    async searchProducts() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getProducts({ ...paginationFilter, ...listFilter }).then(
        (response) => (this.totalRecords = response.data.Total)
      );
    },
    async exportProduct() {
      let listFilter = this.$refs.filter.value();
      let table = this.$refs.table.value();
      let urlSearchParams = new URLSearchParams();

      for (let key in listFilter) {
        if (listFilter[key] && listFilter[key] !== null && listFilter[key] !== "") {
          urlSearchParams.append(key, listFilter[key]);
        }
      }

      if (table.ids) {
        for (let i = 0; i < table.ids.length; i++) {
          urlSearchParams.append("ids", table.ids[i]);
        }
      }

      await this.exportProducts(urlSearchParams).then((res) => {
        this.downloadFile("Produtos Exportação.xlsx", res.data);
      });
    },
    async exportProductMapping() {
      let listFilter = this.$refs.filter.value();
      let table = this.$refs.table.value();

      let urlSearchParams = new URLSearchParams();

      for (let key in listFilter) {
        if (listFilter[key] && listFilter[key] !== null && listFilter[key] !== "") {
          urlSearchParams.append(key, listFilter[key]);
        }
      }

      if (table.ids) {
        for (let i = 0; i < table.ids.length; i++) {
          urlSearchParams.append("ids", table.ids[i]);
        }
      }

      await this.exportProductsMapping(urlSearchParams).then((res) => {
        this.downloadFile("Produtos Exportação Mapeamento.xlsx", res.data);
      });
    },
    downloadFile(file, fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      return;
    },
  },
  async mounted() {
    await this.searchProducts();
  },
};
</script>
