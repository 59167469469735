<template>
  <el-table style="width: 100%" :data="ordersToTable" ref="orderTable" @selection-change="handleSelectionChange"
    header-row-class-name="order-table-header-row">
    <template slot="append">
      <el-row v-if="selectedItems.length > 0">
        <el-col class="table-selection-info">
          <span>{{ selectedMessage }}</span>
          <el-link v-if="selectedAllExisting" @click="clearSelected">Limpar seleção.</el-link>
          <el-link v-else @click="selectedAllExisting = true">Selecionar todos os {{ total }}
            pedidos.</el-link>
        </el-col>
      </el-row>
    </template>
    <el-table-column type="selection" width="40" />
    <el-table-column align="center" label="Pedido" min-width="100" prop="Number">
      <template slot-scope="props">
        <p @click="detail(props.row.Number, props.row.EcommerceName)"
          style="text-decoration: underline; cursor: pointer">{{ props.row.Number }}</p>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Data de Criação" min-width="150" prop="PurchaseDate"
      v-if="checkColumn('ShowCreateDateColumnInOrderList')"></el-table-column>
    <el-table-column align="center" label="Loja" min-width="100" prop="EcommerceName">
    </el-table-column>
    <el-table-column align="center" label="Canal" min-width="110" prop="Channel"
      v-if="checkColumn('ShowChannelColumnInOrderList')"></el-table-column>
    <el-table-column align="center" label="Código Depósito" min-width="110" prop="WarehouseCode"
      v-if="checkColumn('ShowWarehouseColumnInOrderList')">
    </el-table-column>
    <el-table-column align="center" label="Código Filial" min-width="100" prop="BplId"
      v-if="checkColumn('ShowBranchColumnInOrderList')">
    </el-table-column>
    <el-table-column align="center" label="Data de Importação" min-width="150" prop="ImportedDate"
      v-if="checkColumn('ShowImportDateColumnInOrderList')">
    </el-table-column>
    <el-table-column v-if="checkColumn('ShowTargetERPColumnInOrderList')" align="center" label="ERP" min-width="60"
      prop="TargetERP">
      <template slot-scope="props">
        <el-tooltip :content="getToolTipImageERP(props.row.TargetERP)" effect="light" :open-delay="300" placement="top">
          <img width="20" height="20" :src="getPathImageERP(props.row.TargetERP)" />
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column label="Documentos" align="center" :min-width="102"
      v-if="checkColumn('ShowDocumentColumnInOrderList')">
      <template slot-scope="props">
        <document-status v-if="checkColumn('ShowDocumentColumnInOrderList')" :documents="props.row.Documents"
          :erpDocuments="erpDocuments" />
      </template>
    </el-table-column>
    <el-table-column label="Status" align="center" :min-width="150">
      <template slot-scope="props">
        <div :class="props.row.Status.Class">{{ props.row.Status.Name }}</div>
      </template>
    </el-table-column>
    <el-table-column min-width="105" align="center" label="Ações">
      <div slot-scope="props" style="display: inline-flex;">
        <el-tooltip content="Editar pedido" effect="light" :open-delay="300" placement="top">
          <base-button class="like btn-link" type="primary" size="sm" icon
            @click="detail(props.row.Number, props.row.EcommerceName)">
            <i class="material-icons">edit</i>
          </base-button>
        </el-tooltip>
        <el-tooltip content="Reenfileirar pedido" effect="light" :open-delay="300" placement="top">
          <base-button @click="requeue(props.row.EcommerceName, props.row.Id)"
            v-if="props.row.Status.Name != 'Cancelado'" class="edit btn-link" type="primary" size="sm" icon>
            <i class="material-icons">loop</i>
          </base-button>
        </el-tooltip>
        <el-tooltip content="Ver log de integração" effect="light" :open-delay="300" placement="top">
          <base-button class="like !important btn-link" type="primary" size="sm" icon
            @click="showModal(props.row.Id, props.row.Number, props.row.EcommerceName, showAllEcommerce)">
            <i class="material-icons">assignment</i>
          </base-button>
        </el-tooltip>
        <OrderCancel :order="props.row" buttonText="Cancelar" :is-icon="true" @reload-list="clearFilter" />
      </div>
    </el-table-column>
  </el-table>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import DocumentStatus from './DocumentStatus.vue';
import OrderCancel from '../components/OrderCancel.vue';
import { EventBus } from "@/eventBus";
import swal from "sweetalert2";
import constants from '@/util/constants';

export default {
  components: {
    DocumentStatus,
    OrderCancel
  },
  props: {
    total: { type: Number },
    showAllEcommerce: { type: Boolean },
    filterInstance: {
      type: Object | undefined,
      required: true
    }
  },
  data() {
    return {
      selectedItems: [],
      selectedAllExisting: false,
      configurations: [],
      queryParams: {
        Number: null,
        EcommerceName: null,
        Reason: null,
        UserName: localStorage["profile_username"]
      }
    }
  },
  computed: {
    ...mapGetters("orders", ["ordersToTable"]),
    ...mapState("orders", ["erpDocuments"]),
    selectedMessage() {
      return this.selectedAllExisting
        ? `Todos os ${this.total} pedidos foram selecionados.`
        : `${this.selectedItems.length} pedidos selecionados.`;
    },
  },
  methods: {
    ...mapActions("configurations", ["getConfigurationByKeys"]),
    ...mapActions("orders", ["getOrders", "cancelOrder"]),
    showModal(id, number, ecommerceName, showAllEcommerce) {
      this.$router.push({ name: 'LogsOrder', query: { id: id, number: number, ecommerceName: ecommerceName, showAllEcommerce: showAllEcommerce } });
    },
    getPathImageERP(targetERP) {
      switch (targetERP) {
        case constants.TargetERP.PDV:
          return require(`@/assets/images/ciss.png`);
        default:
          return require(`@/assets/images/sap.png`);
      }
    },
    getToolTipImageERP(targetERP) {
      switch (targetERP) {
        case constants.TargetERP.PDV:
          return "CISS";
        default:
          return "SAP";
      }
    },
    detail(number, ecommerceName) {
      this.$router.push({ name: "Details", params: { id: number, ecommerceName } });
    },
    handleSelectionChange(value) {
      this.selectedItems = value.map((x) => ({
        number: x.Number,
        ecommerceName: x.EcommerceName
      }));
      this.selectedAllExisting = false;
      EventBus.$emit("selectionChange", this.selectedItems.length);
    },
    clearSelected() {
      this.$refs.orderTable.clearSelection();
      this.selectedItems = [];
      EventBus.$emit("selectionChange", this.selectedItems.length);
    },
    clearFilter() {
      this.filterInstance.clearFilter()
    },
    requeue(ecommerceName, id) {
      swal({
        title: "Tem certeza?",
        text: "Quer mesmo reenfileirar este pedido?",
        type: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$bahngleis
            .get(`/dashboard/RequeueOrder?ecommerceName=${ecommerceName}&orderId=${id}`)
            .then(() => {
              this.$showSuccess("O pedido foi reenfileirado.");
              this.$emit("refresh");
            }).catch(() => {
              this.$showError("Ocorreu um erro ao reinserir o pedido na fila de integração");
            });
        }
      });
    },
    checkColumn(key) {
      let configuration = this.configurations.find(a => a.Key === key);
      return configuration ? configuration.Value === 'true' : true;
    },
    async getConfigurations() {
      let query = {
        ecommerceName: this.$store.state.ecommerce.Name,
        keys: [constants.Configurations.ShowWarehouseColumnInOrderList,
        constants.Configurations.ShowBranchColumnInOrderList,
        constants.Configurations.ShowChannelColumnInOrderList,
        constants.Configurations.ShowDocumentColumnInOrderList,
        constants.Configurations.ShowImportDateColumnInOrderList,
        constants.Configurations.ShowCreateDateColumnInOrderList,
        constants.Configurations.ShowTargetERPColumnInOrderList]
      };
      await this.getConfigurationByKeys(query).then(response => {
        this.configurations = response.data;
      });
    },
  },
  async mounted() {
    await this.getConfigurations();
  },
}
</script>
<style>
@import "../Order.css";

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}
</style>
