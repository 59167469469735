<template>
  <el-table :data="fulfillmentCurrent.Products">
    <el-table-column label="Sku" min-width="100" prop="SKU">
      <template slot-scope="scope">
        <base-input>
          <el-input style="text-align:center" v-model="scope.row.SKU" :readonly="!shouldDisable()"></el-input>
        </base-input>
      </template>
    </el-table-column>
    <el-table-column label="Descrição" min-width="200" prop="Name">
      <template slot-scope="scope">
        <base-input>
          <el-input style="text-align:center" v-model="scope.row.Name" :readonly="!shouldDisable()"></el-input>
        </base-input>
      </template>
    </el-table-column>
    <el-table-column align="center" label="Preço Unitário" prop="Price">
      <template slot-scope="scope">
        {{ scope.row.Price | toCurrency }}
      </template>
    </el-table-column>
    <el-table-column align="center" label="Quantidade" prop="Quantity">
    </el-table-column>
    <el-table-column align="center" label="Total">
      <template slot-scope="scope">
        {{ (scope.row.Price * scope.row.Quantity) | toCurrency }}
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    configurationChangeSkuProduct: { type: Object }
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
  },
  methods: {
    shouldDisable() {
      return (this.configurationChangeSkuProduct !== null &&
        this.configurationChangeSkuProduct.Value === 'true' &&
        this.fulfillmentCurrent.Status.BahnReference === 'error' &&
        this.fulfillmentCurrent.DocEntry === 0
      );
    },
  }
}
</script>
