<template>
  <div class="row" style="float: right; margin-left: 4px">
    <span class="mr-2">
      <base-input>
        <el-input type="search" prefix-icon="el-icon-search" placeholder="Sku" v-model="filters.sku">
        </el-input>
      </base-input>
    </span>
    <span class="mr-2">
      <button class="custom-btn-filter" @click="filter">Filtrar</button>
    </span>
    <span class="mr-2">
      <button class="custom-btn-filter" @click="clearFilter">
        Limpar filtro
      </button>
    </span>
    <span class="mr-2">
      <button class="custom-btn-add" @click="addNew">Novo</button>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filters: {
        id: null,
        sku: null
      }
    };
  },
  methods: {
    addNew() {
      this.$router.push({ name: "StockConfigurationCreate" });
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        id: null,
        sku: null
      };
      this.$emit("filter");
    },
    value() {
      return this.filters;
    }
  }
};
</script>
