<template>
  <div>
    <el-table :data="stockConfigurations" ref="multipleTable">
      <el-table-column label="Id" min-width="50" align="center" prop="id"></el-table-column>
      <el-table-column label="Sku" min-width="100" prop="sku">
      </el-table-column>
      <el-table-column label="Estoque de segurança inteligente" min-width="100" prop="intelligentSafetyQuantity">
      </el-table-column>
      <el-table-column label="Estoque de segurança básico" min-width="100" prop="basicSafetyQuantity">
      </el-table-column>
      <el-table-column label="Estoque máximo" min-width="100" prop="maximumQuantity">
      </el-table-column>
      <el-table-column label="Status" align="center" min-width="40">
        <div slot-scope="props">
          <div v-bind:class="CheckStatus(props.row.active)"> {{ props.row.active == true ? "Ativo" : "Inativo" }}
          </div>
        </div>
      </el-table-column>
      <el-table-column :min-width="40" align="center" label="Ações">
        <div slot-scope="props">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="edit(props.row.id)">
            <i class="material-icons">mode_edit_outline</i>
          </base-button>
          <base-button class="like !important btn-link" type="danger" size="sm" icon @click="remove(props.row.id)">
            <i class="material-icons">delete_outline</i>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <router-view></router-view>
  </div>
</template>
<script>
import ListBullet from '@/components/Icons/List_Bullet';
import swal from "sweetalert2";
import { mapActions } from 'vuex';

export default {
  components: {
    ListBullet
  },
  props: {
    stockConfigurations: {
      type: Array,
      default: function () {
        return [{
          id: "",
          sku: "",
          intelligentSafetyQuantity: "",
          basicSafetyQuantity: "",
          maximumQuantity: "",
          active: false
        }];
      },
    },
  },
  methods: {
    ...mapActions('stockConfiguration', ['getStockConfigurations', 'removeStockConfiguration']),
    edit(id) {
      this.$router.push({ name: "StockConfigurationDetail", params: { id: id } });
    },
    remove(id) {
      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.value) {
          await this.delete(id)
        }
      });
    },
    async delete(id) {
      await this
        .removeStockConfiguration(id)
        .then(async () => {
          this.$showSuccess("O cadastro foi excluído com sucesso");
          await this.getStockConfigurations();
        })
        .catch(error => { this.$showError(error) });
    },
    CheckStatus: function (status) {
      const statusParams = {
        true: "statusActive",
        false: "statusInactive",
      };

      return `status ${statusParams[status]}` || "status statusDefault";
    },
  },
};
</script>
<style scoped></style>
