<template>
  <div class="modal fade" id="logModal" data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="orderEventsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content bg-color-default">
        <div class="modal-header">
          <h4 slot="header" id="orderEventsModalLabel">
            Eventos
          </h4>
          <button type="button" class="close" @click="closeModal">
            <span aria-hidden="true" class="text-white">
              <i class="tim-icons icon-simple-remove"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="icon-groups">
            <div class="icon-container">
              <el-tooltip :content="tooltipContentXML" effect="light" :open-delay="300" placement="top">
                <div :class="['dotted-circle', getXmlColor]">
                  <i class="el-icon-document custom-icon-size"></i>
                </div>
              </el-tooltip>
              <div class="text-center mt-2">
                <span class="icon-text" :class="getXmlColor">
                  {{ tooltipContentXML }}
                </span>
              </div>
            </div>
            <div class="icon-container">
              <el-tooltip :content="tooltipContentInvoiced" effect="light" :open-delay="300" placement="top">
                <div :class="['dotted-circle', orderEvents.InvoicedAt ? 'green-icon' : 'red-icon']">
                  <i class="tim-icons icon-money-coins custom-icon-size"></i>
                </div>
              </el-tooltip>
              <div class="text-center mt-2">
                <span class="icon-text" :class="orderEvents.InvoicedAt ? 'green-icon' : 'red-icon'">
                  Faturado em: {{ dateFormatter(orderEvents.InvoicedAt) || '-' }}
                </span>
              </div>
            </div>
            <div class="icon-container">
              <el-tooltip :content="tooltipContentShipped" effect="light" :open-delay="300" placement="top">
                <div :class="['dotted-circle', orderEvents.ShippedSynced ? 'green-icon' : 'red-icon']">
                  <i class="tim-icons icon-cloud-upload-94 custom-icon-size"></i>
                </div>
              </el-tooltip>
              <div class="text-center mt-2">
                <span class="icon-text" :class="orderEvents.ShippedSynced ? 'green-icon' : 'red-icon'">
                  Despachado em: {{ dateFormatter(orderEvents.ShippedAt) || '-' }}
                </span>
              </div>
            </div>
            <div class="icon-container">
              <el-tooltip :content="tooltipContentDelivered" effect="light" :open-delay="300" placement="top">
                <div :class="['dotted-circle', orderEvents.DeliveredSynced ? 'green-icon' : 'red-icon']">
                  <i class="tim-icons icon-delivery-fast  custom-icon-size"></i>
                </div>
              </el-tooltip>
              <div class="text-center mt-2">
                <span class="icon-text" :class="orderEvents.DeliveredSynced ? 'green-icon' : 'red-icon'">
                  Entregue em: {{ dateFormatter(orderEvents.DeliveredAt) || '-' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import { mapActions, mapState } from 'vuex';
import moment from "moment";

export default {
  components: {
    Modal
  },
  data() {
    return {
      show: false,
      orderEvents: {
        Id: 0,
        NumberOrder: "",
        EcommerceName: "",
        InvoiceNumber: "",
        AcessKey: "",
        UpdatedEcomm: false,
        Xml: "",
        InvoicedAt: "",
        ShippedSynced: false,
        DeliveredSynced: false,
        DeliveredAt: "",
        ShippedAt: "",
      },
      loading: false,
    }
  }, computed: {
    ...mapState('orders', ['orderCurrent']),
    ...mapState(['ecommerce']),
    tooltipContentShipped() {
      if (this.orderEvents.ShippedSynced) {
        return 'Enviado para o e-commerce.';
      } else if (this.orderEvents.ShippedAt) {
        return 'Despacho recebido na API externa, mas ainda não sincronizado com o e-commerce.';
      } else {
        return 'Despacho ainda não recebido na API externa.';
      }
    },

    tooltipContentDelivered() {
      if (this.orderEvents.DeliveredSynced) {
        return 'Entrega sincronizada no e-commerce.';
      } else if (this.orderEvents.DeliveredAt) {
        return 'Entrega recebida na API externa, mas ainda não sincronizada com o e-commerce.';
      } else {
        return 'Entrega ainda não recebida na API externa.';
      }
    },

    tooltipContentInvoiced() {
      if (this.orderEvents.InvoicedAt) {
        return 'Nota fiscal recebida na API externa.';
      } else {
        return 'Nota fiscal não recebida na API externa.';
      }
    },

    tooltipContentXML() {
      if (this.orderEvents.Xml)
        return 'XML disponível.';
      else if (!this.orderEvents.Xml && this.isWithin90Days(this.orderEvents.InvoicedAt))
        return 'XML removido após 90 dias.';
      else
        return 'XML não disponível.';
    },

    getXmlColor() {
      if (this.orderEvents.Xml || this.isWithin90Days(this.orderEvents.InvoicedAt))
        return "green-icon";
      else
        return "red-icon";
    }
  },
  methods: {
    ...mapActions("orders", ["getOrderEvents"]),
    closeModal() {
      $(".modal-backdrop").hide();
      this.$router.go(-1);
    },
    closeModalOnOutsideClick(event) {
      if (document.body.classList.contains('modal-open') && !event.target.closest('.modal-dialog')) {
        this.closeModal();
      }
    },
    async getEvents() {
      await this.getOrderEvents({ orderNumber: this.orderCurrent.Number, ecommerceName: this.orderCurrent.EcommerceName })
        .then(res => {
          this.orderEvents = res.data;
        })
        .catch(error => { this.$showError(error.response) })
    },
    dateFormatter(value) {
      if (!value) return "-";
      return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
    },
    isWithin90Days(invoicedAt) {
      return moment().diff(moment(invoicedAt), 'days') <= 90;
    }
  },
  async mounted() {
    await this.getEvents();
    setTimeout(() => {
      document.addEventListener('click', this.closeModalOnOutsideClick);
      jQuery("#logModal").modal("show");
    }, 500);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeModalOnOutsideClick);
  },
}
</script>
<style scoped>
@import "../Order.css";

.modal-body {
  display: flex;
  justify-content: center;
}

.icon-groups {
  display: flex;
  gap: 20px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}

.dotted-circle {
  width: 100px;
  height: 100px;
  border: 2px dotted;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.mt-2 {
  margin-top: 8px;
}

.icon-text {
  white-space: normal;
  word-break: break-word;

}

.green-icon {
  color: rgb(35, 207, 35);
}

.red-icon {
  color: rgb(252, 186, 7);
}

.el-icon-size-circle {
  font-size: 40px;
}

.custom-icon-size {
  font-size: 40px;
}
</style>
