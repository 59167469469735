<template>
    <div class="row">
        <card card-body-classes="table-full-width">
            <div class="col-12">
                <div class="d-flex justify-content-center justify-content-between flex-wrap">
                    <div></div>
                    <div>
                        <button class="custom-btn-save" @click="explore">Buscar</button>
                    </div>
                </div>
            </div>
            <h4 slot="header" class="card-title">Consulta externa</h4>
            <base-input class="col-6" label="Onde">
                <el-select v-model="externalSearch.toWhich" placeholder="Selecione">
                    <el-option v-for="item in toWhichOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </base-input>
            <div v-if="externalSearch.toWhich != 's4Hana'">
                <base-input v-if="externalSearch.toWhich !== 'xsEngine'" class="col-6" label="Path"
                    v-model="externalSearch.path" />
                <base-input v-if="externalSearch.toWhich !== 'xsEngine'" class="col-6" label="Filtro"
                    v-model="externalSearch.filter" />
                <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="Select"
                    v-model="externalSearch.select" />
                <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="Expand"
                    v-model="externalSearch.expand" />
                <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="OrderBy"
                    v-model="externalSearch.orderBy" />
                <base-input v-if="externalSearch.toWhich == 'erp'" class="col-6" label="Top"
                    v-model="externalSearch.top" />
            </div>
            <S4HanaForm ref="formS4Hana" v-else />
            <base-input class="col-6" label="Resultado">
                <el-input v-model="resultSearchExternal" type="textarea" :autosize="{ minRows: 20, maxRows: 50 }" />
            </base-input>
        </card>
        <div class="col-12">
            <UpdateLog :dataLog="logs" />
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import UpdateLog from "../../../components/UpdateLog.vue";
import constants from '@/util/constants'
import S4HanaForm from "../components/S4HanaForm.vue";

export default {
    components: {
        UpdateLog,
        S4HanaForm
    },
    computed: {
        ...mapState('externalSearch', ['logs'])
    },
    data() {
        return {
            externalSearch: {
                path: "",
                filter: "",
                expand: "",
                orderBy: "",
                select: "",
                toWhich: "",
                top: ""
            },
            toWhichOptions: constants.ToWhichOptions,
            resultSearchExternal: ""
        };
    },
    methods: {
        ...mapActions("externalSearch", ['search', 'getLogs']),
        async load() {
            await this.getLogs();
        },
        async explore() {
            if (!this.validate()) return;

            let payload = {};
            Object.values(this.$refs).forEach((ref) =>
                Object.assign(payload, ref.getValue())
            );

            payload = { ...payload, ...this.externalSearch };

            await this.search(payload).then(response => {
                this.jsonData(response.data);
                this.$showSuccess("Consulta realizada com sucesso");
            }).catch(error => { this.$showError(error) });
        },
        jsonData(data) {
            try {
                this.resultSearchExternal = JSON.stringify(data, null, 2);
            } catch (error) {
                return {};
            }
        },
        validate() {
            if (this.externalSearch.toWhich === 's4Hana') {
                if (Object.values(this.$refs).some((ref) => !ref.validate()))
                    return false;
                else return true;
            } else if (this.$isNullOrEmpty(this.externalSearch.path) && this.externalSearch.toWhich !== 'xsEngine') {
                this.$showError("Path obrigatório.");
                return false;
            } else if (this.externalSearch.path.includes('?')) {
                this.$showError("Path não pode conter '?'");
                return false;
            } else if (!this.$isNullOrEmpty(this.externalSearch.filter) && this.externalSearch.filter.includes('?')) {
                this.$showError("Filter não pode conter '?'");
                return false;
            } else if (this.$isNullOrEmpty(this.externalSearch.toWhich)) {
                this.$showError("Onde obrigatório.");
                return false;
            } else
                return true;
        },
    },
    async mounted() {
        await this.load();
    },
}
</script>
<style scoped></style>
