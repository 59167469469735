<template>
  <el-table :data="customFields" style="width: 100%">
    <el-table-column label="Id" align="center" prop="Id" />
    <el-table-column label="Nome" prop="Name" />
    <el-table-column width="180" label="Tipo do Documento" align="left">
      <div slot-scope="props">
        {{ getDocumentType(props.row.DocumentType) }}
      </div>
    </el-table-column>
    <el-table-column label="Tipo de Regra" align="center"  min-width="150">
      <div slot-scope="props">
        {{ getDocumentTypeRule(props.row.TypeRule) }}
      </div>
    </el-table-column>
    <el-table-column label="Possui Condições?" align="center">
      <div slot-scope="props">
        <i v-if="anyCondition(props.row)" class="far fa-check-circle green-icon"></i>
        <i v-else class="far fa-times-circle red-icon"></i>
      </div>
    </el-table-column>
    <el-table-column label="Campo do ERP" prop="ErpField" align="center"></el-table-column>
    <el-table-column width="200" label="Campo do Ecommerce" prop="PlatformField" align="center"></el-table-column>
    <el-table-column label="Status" align="center" min-width="40">
      <div slot-scope="props">
        <div v-bind:class="CheckStatus(props.row.Active || false)
          ">
          {{ props.row.Active == true ? "Ativo" : "Inativo" }}
        </div>
      </div>
    </el-table-column>
    <el-table-column :min-width="100" align="center" label="Ações">
      <div slot-scope="props">
        <base-button v-if="(getUserPermission('30_c') && props.row.TypeRule !== 0) || getUserPermission('00_c')"
          class="like !important btn-link" type="primary" size="sm" icon @click="showDetails(props.row)">
          <i class="material-icons">mode_edit_outline</i>
        </base-button>
        <base-button v-if="(getUserPermission('30_c') && props.row.TypeRule !== 0) || getUserPermission('00_c')"
          class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row.Id)">
          <i class="material-icons">delete_outline</i>
        </base-button>
      </div>
    </el-table-column>
  </el-table>
</template>
<script>
import constants from '@/util/constants'

export default {
  props: {
    customFields: {
      type: Array
    },
  },
  components: {
  },
  data() {
    return {
      selectedEcomm: "",
      fields: [],
    }
  },
  methods: {
    getDocumentType(key) {
      return constants.CustomFieldDocumentType.find(a => a.Key === key).Label;
    },
    getDocumentTypeRule(key) {
      return constants.CustomFieldTypeRule
        .sort((a, b) => a.Label.localeCompare(b.Label))
        .find(a => a.Key === key).Label;
    },
    getRuleType(key) {
      return constants.CustomFieldRule.find(a => a.Key === key).Label;
    },
    showDetails(row) {
      switch (row.TypeRule) {
        case 0:
        case 8:
          this.$router.push({ name: 'CustomFieldDetail', query: { id: row.Id } })
          break;
        default:
          this.$router.push({ name: 'OrderRuleDetail', query: { id: row.Id } })
      }
    },
    anyCondition(row) {
      return row.CustomFieldCondition.length > 0 ? true : false;
    },
    remove(id) {
      this.$emit("remove", id);
    },
    CheckStatus: function (status) {
      const statusParams = {
        true: "statusActive",
        false: "statusInactive",
      };

      return `status ${statusParams[status]}` || "status statusDefault";
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
  },
}
</script>
<style scoped>
.green-icon {
  color: rgb(35, 207, 35);
}

.red-icon {
  color: rgb(241, 15, 15);
}
</style>
