<template>
    <div>
        <base-input label="Path" class="col-6">
            <el-select class="select-primary" v-model="externalSearch.typeSearch" placeholder="Informe o Path" filterable>
                <el-option v-for="item in s4HanaRoutesOptions" :key="item.Key" :label="item.Label" :value="item.Key">
                </el-option>
            </el-select>
        </base-input>
        <base-input label="Depósito" class="col-6"
            v-if="[s4HanaRoutesEnum.ItemPrice, s4HanaRoutesEnum.ItemStock].includes(externalSearch.typeSearch)">
            <el-select class="select-primary" v-model="externalSearch.warehouseId" placeholder="Informe o Depósito"
                filterable>
                <el-option v-for="item in warehouses" :key="item.Id"
                    :label="item.Filial + ' - ' + item.WarehouseEcommerce + ' - ' + item.Plant" :value="item.Id">
                </el-option>
            </el-select>
        </base-input>
        <base-input
            :label="[s4HanaRoutesEnum.ItemPrice, s4HanaRoutesEnum.ItemStock].includes(externalSearch.typeSearch) ? 'Sku' : 'Pedido'"
            class="col-6">
            <el-input v-model="externalSearch.skuOrOrderNumber"
                :placeholder="[s4HanaRoutesEnum.ItemPrice, s4HanaRoutesEnum.ItemStock].includes(externalSearch.typeSearch) ? 'Informe o Sku' : 'Informe o Pedido'"></el-input>
        </base-input>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import constants from "@/util/constants";

export default {
    data() {
        return {
            externalSearch: {
                typeSearch: "",
                skuOrOrderNumber: "",
                warehouseId: 0,
            },
            warehouses: [],
            s4HanaRoutesOptions: constants.S4HanaRoutes,
            s4HanaRoutesEnum: constants.S4HanaRoutesEnum,
        }
    },

    methods: {
        ...mapActions("configurations", ["getWarehouses"]),
        validate() {
            if (this.$isNullOrEmpty(this.externalSearch.typeSearch)) {
                this.$showError("Informe o Path");
                return false;
            } else if (this.$isNullOrEmpty(this.externalSearch.skuOrOrderNumber)) {
                this.$showError("Informe o Filtro");
                return false;
            } else if ([this.s4HanaRoutesEnum.ItemPrice, this.s4HanaRoutesEnum.ItemStock].includes(this.externalSearch.typeSearch) && this.$isNullOrEmpty(this.externalSearch.warehouseId)) {
                this.$showError("Informe o Depósito");
                return false;
            }
            return true;
        },
        getValue() {
            return this.externalSearch;
        },
    },
    async mounted() {
        this.getWarehouses()
            .then((res) => {
                this.warehouses = res.data;
            })
            .catch((error) => {
                this.$showError(`Erro ao buscar Warehouses, motivo: ${error.response.data}`);
            });
    },
}
</script>
<style scoped>
.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}
</style>
