<template>
  <BaseList :title="title" ref="baseList" :totalRecords="totalRecords" @changePage="searchFranchises">
    <FranchisesFilter ref="filter" @filter="searchFranchises" />
    <FranchisesTable :franchises="franchisesToTable" @evtRefresh="searchFranchises" />
  </BaseList>
</template>
<script>
import BaseList from "@/components/BaseList";
import FranchisesTable from "@/pages/Franchise/components/FranchiseTable";
import FranchisesFilter from "@/pages/Franchise/components/FranchiseFilter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseList,
    FranchisesTable,
    FranchisesFilter,
  },
  data() {
    return {
      title: "Franquias",
      totalRecords: 0
    };
  },
  computed: {
    ...mapGetters("franchise", ["franchisesToTable"])
  },
  methods: {
    ...mapActions("franchise", ["getFranchises"]),
    async searchFranchises() {
      let paginationFilter = this.$refs.baseList.value();
      let listFilter = this.$refs.filter.value();

      await this.getFranchises({ ...paginationFilter, ...listFilter })
        .then((response) => this.totalRecords = response.data.Total);
    },
  },
  async mounted() {
    await this.searchFranchises();
  },
};
</script>
