<template>
  <UpdateLog :dataLog="logs" />
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UpdateLog from "../../../components/UpdateLog.vue"
import { EventBus } from "@/eventBus";

export default {
  components: {
    UpdateLog
  },
  data() {
    return {
      logs: [],
    }
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
    ...mapState("health", ["displayRestrictedData"])
  },
  methods: {
    ...mapActions("fulfillment", ["getFulfillmentLogs"]),
    search() {
      let params = {
        id: this.fulfillmentCurrent.ID,
        displayRestrictedData: this.displayRestrictedData
      };
      
      this.getFulfillmentLogs(params)
        .then((res) => this.logs = res.data)
        .catch((error) => this.$showError(error))
    }
  },
  mounted() {
    EventBus.$on("toggleRestrictedDataDisplay", async () => {
      this.search();
    });
    this.search();
  },
}
</script>
