<template>
  <div class="row">
    <div class="col-6 d-flex justify-content-start">
      <button class="custom-flexible-btn ml-3 mr-3" @click="expandAll">{{ buttonName }}</button>
      <button v-if="getUserPermission('00_c')" class="custom-flexible-btn mr-3 d-flex align-items-center"
        @click="showModalCustomFields()"><i class="material-icons mr-2">tune</i> Campos
        Customizados</button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button v-if="getUserPermission('29_c') && (fulfillmentCurrent.Status.BahnReference == 'error')"
        class="custom-btn-save mr-3" @click="updateModal">
        Atualizar
      </button>
    </div>
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template slot="title">
          <h4><i class="header-icon el-icon-info"></i> Dados Gerais </h4>
        </template>
        <FullfillmentFormGeneral />
      </el-collapse-item>
      <el-collapse-item name="2" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-user"></i> Cliente </h4>
        </template>
        <FullfillmentFormClient />
      </el-collapse-item>
      <el-collapse-item name="3" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-money"></i> Pagamento </h4>
        </template>
        <FulfillmentFormPayment />
      </el-collapse-item>
      <el-collapse-item name="4" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-truck"></i> Entrega </h4>
        </template>
        <FulfillmentFormDelivery />
      </el-collapse-item>
      <el-collapse-item name="5" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-takeaway-box"></i> Produto(s) </h4>
        </template>
        <FullfillmentFormProducts :configurationChangeSkuProduct="configurationChangeSkuProduct" />
      </el-collapse-item>
      <el-collapse-item name="6" style="margin-top:40px">
        <template slot="title">
          <h4><i class="header-icon el-icon-tickets"></i> Logs </h4>
        </template>
        <FulfillmentFormLogs />
      </el-collapse-item>
    </el-collapse>
    <router-view></router-view>
  </div>
</template>
<script>
import FullfillmentFormGeneral from './FullfillmentFormGeneral.vue';
import FullfillmentFormClient from './FullfillmentFormClient.vue';
import FullfillmentFormProducts from './FullfillmentFormProducts.vue';
import FulfillmentFormLogs from './FulfillmentFormLogs.vue';
import FulfillmentFormPayment from './FulfillmentFormPayment.vue';
import FulfillmentFormDelivery from './FulfillmentFormDelivery.vue';
import { mapState, mapActions } from 'vuex';
import swal from "sweetalert2";

export default {
  components: {
    FullfillmentFormGeneral,
    FullfillmentFormClient,
    FullfillmentFormProducts,
    FulfillmentFormLogs,
    FulfillmentFormPayment,
    FulfillmentFormDelivery
  },
  data() {
    return {
      activeNames: ["1", "2", "3", "4", "5"],
      test: ""
    }
  },
  props: {
    configurationChangeSkuProduct: { type: Object }
  },
  computed: {
    ...mapState("fulfillment", ["fulfillmentCurrent"]),
    ...mapState("health", ["displayRestrictedData"]),
    buttonName() {
      return this.activeNames.length > 0 ? "Recolher Todos" : "Expandir Todos";
    }
  },
  methods: {
    ...mapActions("orders", ["updateOrder"]),
    expandAll() {
      this.activeNames.length > 0 ? this.activeNames = [] : this.activeNames = ["1", "2", "3", "4"];
    },
    showModalCustomFields() {
      this.$router.push({ path: `${this.fulfillmentCurrent.ID}/CustomFields` });
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    updateModal() {
      if (!this.validate())
        return;

      if (!this.displayRestrictedData) {
        swal({
          title: "Ops!",
          text: "Não é possível atualizar quando os dados estão anonimizados.",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Ok',
          buttonsStyling: false
        });
        return;
      }

      swal({
        title: "Confirmar atualização?",
        text: "Após a confirmação o pedido fullfilment será processado automaticamente.",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        buttonsStyling: false,
        input: 'text',
        inputPlaceholder: 'Motivo da atualização',
        inputLabel: 'Deseja fornecer um motivo para a atualização?',
      }).then(async (result) => {
        if (result.dismiss !== 'cancel') {
          const reason = result.value || '';
          await this.update(reason);
        }
      });
    },
    validate() {
      let isValidateSku = this.fulfillmentCurrent.Products.every(product => product.SKU.trim() !== '');
      let isValidateName = this.fulfillmentCurrent.Products.every(product => product.Name.trim() !== '');

      if (!isValidateSku) {
        this.$showError("Campo SKU do Produto(s) é obrigatório.");
        return false;
      }

      if (!isValidateName) {
        this.$showError("Campo Nome do Produto(s) é obrigatório.");
        return false;
      }

      return true;
    },
    update(reason) {
      this.fulfillmentCurrent.UpdateReason = reason;

      this.updateOrder(this.fulfillmentCurrent).then(() => {
        this.$bahngleis.get(`/dashboard/RequeueOrder?ecommerceName=${this.fulfillmentCurrent.EcommerceName}&orderId=${this.fulfillmentCurrent.ID}&includeOrdersFulfillment=true`)
          .then(() => {
            this.$showSuccess("Dados atualizados com sucesso.");
            this.$router.go(-1);
          })
          .catch((error) => this.$showError(error))
        this.$showSuccess("Dados atualizados com sucesso.");
        this.$router.go(-1);
      })
        .catch((error) => this.$showError(error))
    }
  },
  async mounted() {
    let section = this.$route.query.section;
    if (section)
      this.activeNames.push(section);
  },
}
</script>
<style>
.el-collapse-item__wrap {
  background-color: #27293d;
}

.el-collapse-item__header {
  background: #1e1e2e;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 5px;
  border-color: #00adb7;
  border-bottom-width: 1.5px;
  margin-bottom: 1em;
}

.el-collapse-item__header:hover {
  background-color: #1aac96;
  color: white;
  transition: background-color 0.5s ease;
}

.el-collapse {
  border-color: rgba(225, 225, 225, 0.187);
}

.el-icon-arrow-right:before {
  color: white;
}
</style>
