<template>
  <div>
    <base-list ref="baseList" :totalRecords="totalRecords" @changePage="search">
      <h4 slot="header" class="card-title">Histórico de Estoque</h4>
      <LogStockFilter ref="filter" @filter="filter" />
      <h4 v-if="!isVulcabras">Captados no ERP</h4>
      <LogStockTable ref="table" :logs="logStocks" />
      <el-divider></el-divider>
      <h4 v-if="!isVulcabras">Enviados para o Ecommerce</h4>
      <LogStockEcommerceTable v-if="!isVulcabras" ref="tableEcommerce" :logs="logEcommerceStocks" />
      <router-view></router-view>
    </base-list>
    <UpdateLog :showOldValue="false" :labelNewValue="'SKU(s) CAPTADO(s)'" :dataLog="dataLog" />
  </div>
</template>
<script>
import BaseList from "@/components/BaseList.vue";
import LogStockFilter from "./components/LogStockFilter.vue";
import LogStockTable from "./components/LogStockTable.vue";
import LogStockEcommerceTable from "./components/LogStockEcommerceTable.vue";
import UpdateLog from "@/components/UpdateLog.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseList,
    LogStockFilter,
    LogStockTable,
    UpdateLog,
    LogStockEcommerceTable,
  },
  data() {
    return {
      logStocks: [],
      logEcommerceStocks: [],
      totalRecords: 0,
      dataLog: [],
    isVulcabras: false,
    };
  },
  computed: {
    ...mapState(["ecommerce", "realm"]),
  },
  methods: {
    ...mapActions("logOrder", ["getLogStock", "getLogCustomEvent"]),
    async filter() {
      this.$refs.baseList.defaultPagination();
      await this.search();
    },
    async search() {
      let pagination = this.$refs.baseList.value();
      let filter = this.$refs.filter.value();

      await this.getLogStock({
        ecommerceName: this.ecommerce.Name,
        currentPage: pagination.pageNumber,
        perPage: pagination.pageSize,
        ...filter,
      })
        .then((response) => {
          if (this.isVulcabras) {
            this.logStocks = response.data.Items;
          } else {
            this.logStocks = response.data.LogStockErp;
            this.logEcommerceStocks = response.data.LogStockEcommerce;
          }
          this.totalRecords = response.data.Total;
        })
        .catch((error) => this.$showError(error.response.data));
    },
    async getLogAction() {
      await this.getLogCustomEvent()
        .then((response) => {
          this.dataLog = response.data;
        })
        .catch((error) => this.$showError(error.response.data));
    },
  },
  async mounted() {
    if (this.realm === "vulcabras")
        this.isVulcabras = true;

    await this.getLogAction();
    await this.search();
  },
};
</script>
<style></style>
