<template>
  <div class="card-body">
    <div class="row col-6">
      <div class="col-12">
        <base-input label="Nome para a configuração">
          <el-input placeholder="Informe Nome para a configuração" v-model="form.Name" :required="true"></el-input>
        </base-input>
      </div>
    </div>
    <el-collapse v-model="activeNames">
      <el-collapse-item name="1">
        <template slot="title">
          <h4><i class="header-icon el-icon-info"></i> Referência no E-Commerce </h4>
        </template>
        <div class="row col-12">
          <div class="col-4">
            <base-input label="Referência no E-Commerce">
              <el-input placeholder="Informe a Referência no E-Commerce" v-model="form.EcommerceReference"></el-input>
            </base-input>
          </div>
          <div class="col-2">
            <base-input label="Parcela">
              <el-input type="number" min="0" placeholder="Informe a Parcela" v-model="form.Installments"></el-input>
            </base-input>
          </div>
          <div class="col-3">
            <base-input label="Referência no HUB">
              <el-input placeholder="Informe a Referência no HUB" v-model="form.HubReference"></el-input>
            </base-input>
          </div>
          <div class="col-3">
            <base-input label="Canal de Venda">
              <el-input placeholder="Informe o Canal de Venda" v-model="form.Channel"></el-input>
            </base-input>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          <h4><i class="header-icon el-icon-info"></i> Referência no ERP</h4>
        </template>
        <div class="row">
          <div class="col-6">
            <card type="tasks" class="stacked-form">
              <h4 class="card-title">Formas de Pagamento</h4>
              <div class="table-full-width table-responsive" v-if="!enableErpReferenceAndPaymentMethodManual">
                <div v-for="item in erpTable" :key="item.PaymentMethodCode" class="custom-radio">
                  <el-radio v-model="form.ErpReference" :label="item.PaymentMethodCode">
                    {{ item.Description }}
                  </el-radio>
                </div>
              </div>
              <div class="table-full-width table-responsive" v-else>
                <base-input label="Forma de Pagamento">
                  <el-input placeholder="Forma de Pagamento" v-model="form.ErpReference"></el-input>
                </base-input>
              </div>
            </card>
          </div>
          <div class="col-6">
            <card type="tasks" class="stacked-form">
              <h4 class="card-title">Condições de Pagamento</h4>
              <div class="table-full-width table-responsive" v-if="!enableErpReferenceAndPaymentMethodManual">
                <div v-for="item in paymentConditionTable" :key="item.PaymentTermsGroupName" class="custom-radio">
                  <el-radio v-model="form.PaymentCondition" :label="item.PaymentTermsGroupName">
                    {{ item.PaymentTermsGroupName }}
                  </el-radio>
                </div>
              </div>
              <div class="table-full-width table-responsive" v-else>
                <base-input label="Condição de pagamento">
                  <el-input placeholder="Condição de pagamento" v-model="form.PaymentCondition"></el-input>
                </base-input>
              </div>
            </card>
          </div>
          <div class="col-6">
            <card type="tasks" class="stacked-form">
              <div class="table-full-width table-responsive">
                <base-input label="Referência no PDV">
                  <el-input placeholder="Informe a Referência no PDV" v-model="form.PdvReference"></el-input>
                </base-input>
              </div>
            </card>
          </div>
          <div class="col-6">
            <card type="tasks" class="stacked-form">
            <div class="table-full-width table-responsive">
              <base-input label="Tipo de Referência no PDV">
                <el-input placeholder="Informe o Tipo cartão no PDV" v-model="form.PdvCardType"></el-input>
              </base-input>
            </div>
          </card>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { mapState } from "vuex";
import { EventBus } from "@/eventBus";
import validateMixin from "@/mixins/validateMixin";
import constants from "@/util/constants";

export default {
  mixins: [validateMixin],
  data() {
    return {
      activeNames: [],
      form: {
        Id: null,
        Name: null,
        EcommerceReference: null,
        Installments: null,
        HubReference: null,
        Channel: null,
        ErpReference: null,
        PaymentCondition: null,
        PdvReference: null,
        PdvCardType: null,
      },
      enableErpReferenceAndPaymentMethodManual: false,
      erpTable: [],
      paymentConditionTable: [],
    };
  },
  validations() {
    return {
      form: {
        Name: { required: helpers.withMessage("Nome obrigatório", required) },
        EcommerceReference: { required: helpers.withMessage("Referência no E-commerce obrigatório", required) },
        ErpReference: { required: helpers.withMessage("Referência no ERP obrigatório", required) },
        PaymentCondition: { required: helpers.withMessage("Condição de pagamento obrigatório", requiredIf(!this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform))) }
      },
    };
  },
  computed: {
    ...mapState("paymentMethod", ["paymentMethodCurrent", "erpPaymentMethods", "paymentConditions"]),
    ...mapState(['ecommerce'])
  },
  methods: {
    fillForm() {
      if (this.paymentMethodCurrent) {
        this.form.Id = this.paymentMethodCurrent.Id;
        this.form.Name = this.paymentMethodCurrent.Name;
        this.form.EcommerceReference = this.paymentMethodCurrent.EcommerceReference;
        this.form.Installments = this.paymentMethodCurrent.Installments;
        this.form.HubReference = this.paymentMethodCurrent.HubReference;
        this.form.Channel = this.paymentMethodCurrent.Channel;
        this.form.ErpReference = this.paymentMethodCurrent.ErpReference;
        this.form.PaymentCondition = this.paymentMethodCurrent.PaymentCondition;
        this.form.PdvReference = this.paymentMethodCurrent.PdvReference;
        this.form.PdvCardType = this.paymentMethodCurrent.PdvCardType;
        this.form.CreatedAt = this.paymentMethodCurrent.CreatedAt;
        this.form.UpdatedAt = this.paymentMethodCurrent.UpdatedAt;
      }
    },
    value() {
      return { ...this.form };
    },
    GetErpPlatformTypeByKey(key) {
      return ["Sap S/4 Hana", "Sap XML"].includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    async EnableErpReferenceAndPaymentMethodManual() {
      if (this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform)) {
        this.enableErpReferenceAndPaymentMethodManual = true;
      } else {
        this.erpTable = this.erpPaymentMethods;
        this.paymentConditionTable = this.paymentConditions;
        this.enableErpReferenceAndPaymentMethodManual = false;
      }
    },
  },
  created() {
    EventBus.$on("PaymentMethodEdition", () => this.fillForm());
  },
  async mounted() {
    await this.EnableErpReferenceAndPaymentMethodManual();
  }
};
</script>
<style>
.el-radio__input.is-checked+.el-radio__label {
  color: #00b7a9 !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #00b7a9;
  background: #00b7a9;
}

.el-icon-arrow-right:before {
  color: white;
}

.el-icon-arrow-right:before {
  color: white;
}

.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-radius: 5px;
}

.el-collapse-item__wrap {
  background-color: #27293d;
}

.el-collapse-item__header {
  background: #1e1e2e;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 5px;
  border-color: #00adb7;
  border-bottom-width: 1.5px;
  margin-bottom: 1em;
}

.el-collapse-item__header.is-active {
  background: #1e1e2e;
  padding-top: 10px;
  padding-left: 10px;
  margin-top: 5px;
  border-color: #00adb7;
  border-bottom-width: 1.5px;
  margin-bottom: 1em;
}

.el-collapse-item__header:hover {
  color: white;
  transition: background-color 0.5s ease;
}

.el-collapse {
  border-color: rgba(225, 225, 225, 0.187);
}

.el-icon-arrow-right:before {
  color: white;
}

.el-collapse-item__content {
  color: white;
}
</style>
