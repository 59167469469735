<template>
  <div>
    <div class="pl-2 mt-2 row">
      <span class="mr-2">
        <base-input>
          <el-date-picker type="date" format="dd/MM/yyyy" placeholder="De" v-model="filters.from"></el-date-picker>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-date-picker type="date" format="dd/MM/yyyy" placeholder="Até" v-model="filters.to"></el-date-picker>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-input type="search" prefix-icon="el-icon-search" placeholder="N° do pedido" @keyup.enter.native="filter"
            v-model="filters.orderNumber" />
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-select class="select-primary" v-model="filters.status" placeholder="Status" multiple filterable
            style="width: 250px;">
            <el-option class="select-primary" v-for="(item, index) in statusList" :key="index" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-select class="select-primary" v-model="filters.warehouses" placeholder="Código depósito" multiple>
            <el-option class="select-primary" v-for="warehouse in warehouses" :key="warehouse" :label="warehouse"
              :value="warehouse"></el-option>
          </el-select>
        </base-input>
      </span>
      <span class="mr-2">
        <button class="custom-btn-filter" @click="filter">Filtrar</button>
      </span>
      <span class="mr-2">
        <button class="custom-btn-filter" @click="clearFilter">
          Limpar filtro
        </button>
      </span>
      <span class="mr-2">
        <el-button class="custom-btn-filter" @click="requeue">
          Reenfileirar
        </el-button>
      </span>
      <span class="mr-2">
        <base-input>
          <el-dropdown @command="handleCommand">
            <el-button class="custom-btn-filter">
              Exportar<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="exportAll">Todos</el-dropdown-item> -->
              <el-dropdown-item command="exportErrors">Somente com erro</el-dropdown-item>
              <el-dropdown-item command="exportCanceled">Somente cancelados</el-dropdown-item>
              <el-dropdown-item command="exportAwaitingCancellation">Somente aguardando cancelamento</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </base-input>
      </span>
      <span class="mr-2">
        <base-input>
          <el-dropdown>
            <el-button class="custom-btn-filter">
              Ações<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <span @click="$router.push({ name: 'SyncStatus' })">Atualizar Status</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="$router.push({ name: 'OrderSyncOrder' })">Sincronizar Pedidos</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="$router.push({ name: 'SyncIncomingPayment' })">Sincronizar Contas a Receber</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="updateWarehouses">Atualizar Depósitos</span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span @click="orderCancel"> Cancelar </span>
              </el-dropdown-item>
              <el-dropdown-item v-if="realm === 'proxys'">
                <span @click="buildReturnInvoicesFile"> Gerar arquivos de devolução </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </base-input>
      </span>
    </div>
    <ManualRequeueOrders @close="showModal = false" :showModal="showModal" />
    <ManualUpdateWarehouses @close="closeModalUpdateWarehouse" :showModalWarehouse="showModalWarehouse" />
    <ManualCancelOrders @close="showModalCancel = false" :showModalCancel="showModalCancel" />
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "@/eventBus";
import { mapActions, mapState } from 'vuex';
import constants from "@/util/constants";
import ManualRequeueOrders from "./ManualRequeueOrders.vue";
import ManualUpdateWarehouses from "./ManualUpdateWarehouses.vue";
import ManualCancelOrders from "./ManualCancelOrders.vue";

export default {
  components: {
    ManualRequeueOrders,
    ManualUpdateWarehouses,
    ManualCancelOrders
  },
  data() {
    return {
      filters: {
        from: null,
        to: null,
        orderNumber: null,
        status: null,
        warehouses: null
      },
      existItems: false,
      statusList: [],
      warehouses: [],
      showModal: false,
      showModalWarehouse: false,
      showModalCancel: false
    }
  },
  computed: {
    ...mapState(['realm'])
  },
  methods: {
    ...mapActions("configurations", ["getWarehouses"]),
    updateWarehouses() {
      if (this.existItems)
        this.$emit("changeWarehouses");
      else
        this.showModalWarehouse = true;
    },
    requeue() {
      if (this.existItems)
        EventBus.$emit("requeue");
      else
        this.showModal = true;
    },
    orderCancel() {
      if (this.existItems)
        EventBus.$emit("cancel");
      else
        this.showModalCancel = true;
    },
    buildReturnInvoicesFile() {
      this.$router.push({
        name: "BuildReturnInvoicesFile",
      });
    },
    filter() {
      this.$emit("filter");
    },
    clearFilter() {
      this.filters = {
        from: null,
        to: null,
        orderNumber: null,
        status: [],
        warehouses: null
      };

      this.$emit("filter");
    },
    value() {
      if (this.filters.from)
        this.filters.from = moment(this.filters.from)
          .format('YYYY-MM-DD HH:mm:ss');

      if (this.filters.to)
        this.filters.to = moment(this.filters.to)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DD HH:mm:ss');

      return this.filters;
    },
    handleCommand(command) {
      this.$emit("handleCommand", command);
    },
    async getStatus() {
      await this.$bahngleis.get(`Status/GetByWithFilter?ecommerceName=${this.$store.state.ecommerce.Name}`)
        .then((response) => {
          response.data
            .forEach(sts =>
              this.statusList.push(
                {
                  name: sts.AliasName,
                  value: sts.BahnReference,
                  id: sts.ID
                }
              ));
        });

      this.statusList = this.statusList.sort((a, b) => a.name.localeCompare(b.name));
    },
    checkParams() {
      let orderType = this.$route.query.orderType;

      if (!orderType) return;

      this.filters = {
        from: null,
        to: null,
        orderNumber: null,
        status: [],
        warehouses: null
      }

      switch (orderType) {
        case "error":
          if (!this.filters.status.some(a => a === constants.StatusOrder.Erro))
            this.filters.status.push(constants.StatusOrder.Erro);

          this.filter();
          break;
        case "processing":
          if (!this.filters.status.some(a => a === constants.StatusOrder.Processing))
            this.filters.status.push(constants.StatusOrder.Processing);

          this.filter();
          break;
        case "today":
          this.filters.from = moment().startOf('day');
          this.filters.useIntegrationDate = true;
          this.filter();
          break;
        case "lastHours":
          this.filters.from = moment().subtract(1, 'hours').format('lll');
          this.filters.useIntegrationDate = true;
          this.filter();
          break;
        case "waitingTransfer":
          if (!this.filters.status.some(a => a === constants.StatusOrder.WaitingTransfer))
            this.filters.status.push(constants.StatusOrder.WaitingTransfer);

          this.filter();
          break;
      }
    },
    async getWarehousesFilter() {
      this.getWarehouses()
        .then(res => {
          res.data.forEach((el) => {
            if (!this.warehouses.find(x => x == el.Code))
              this.warehouses.push(el.Code);
          });
        })
        .catch(error => { this.$showError(`Erro ao buscar Warehouses, motivo: ${error.response.data}`) });
    },
    closeModalUpdateWarehouse() {
      this.showModalWarehouse = false
      this.filter();
    }
  },
  async mounted() {
    await this.getStatus();
    await this.getWarehousesFilter();

    EventBus.$on("selectionChange", (items) => items > 0 ? this.existItems = true : this.existItems = false);

    this.checkParams();

    let orderType = this.$route.query.orderType;

    if (!orderType) {
      let filters = sessionStorage.getItem('filters')
      if (filters) {
        this.filters = JSON.parse(filters);
        this.filter();
      }
    }
  },
  beforeDestroy() {
    sessionStorage.setItem('filters', JSON.stringify(this.filters))
  }
}
</script>
